.containerSideBar {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
}
.containerSideBar__Bar {
  display: flex;
  background: #fafbfd;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 20vw;
}
.containerSideBar__title {
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.containerSideBar__title p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 19px;
  color: black;
}
.containerSideBar__title p span {
  color: #f7542e;
}
.containerSideBar__link {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.containerSideBar__link__item {
  font-family: Noto Sans JP;
  font-style: normal;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #9e9e9e;
}

.containerSideBarContent {
  height: 100vh;
  width: 75vw;
}
