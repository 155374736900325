.containerInput {
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
}
.containerInput p {
  transition-property: color;
  transition-timing-function: ease;
  transition-duration: 0.5s;
}
.containerInput input {
  width: 200px;
  height: 56px;
  outline: none;
  border: 1px solid #828282;
  box-sizing: border-box;
  padding-left: 10px;
  border-radius: 8px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #828282;
  transition-property: border;
  transition-timing-function: ease;
  transition-duration: 0.5s;
}
.containerInput input:hover {
  border: 1px solid #333333;
}
.focus.containerInput p {
  color: #2962ff;
}
.focus.containerInput input {
  border: 1px solid #2962ff;
}

.error input {
  border: 1px solid #d32f2f;
}
.error p {
  color: #d32f2f;
}
.hover.error p {
  color: #333333;
}
.focus.error input {
  border: 1px solid #d32f2f;
}
.focus.error p {
  color: #d32f2f;
}
.sm input {
  width: 200px;
  height: 40px;
}
.md input {
  width: 200px;
  height: 56px;
}
.fullWidth input {
  width: 98vh;
}

.containerInput textarea {
  outline: none;
  border: 1px solid #828282;
  box-sizing: border-box;
  padding-left: 10px;
  border-radius: 8px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #828282;
  transition-property: border;
  transition-timing-function: ease;
  transition-duration: 0.5s;
}
.containerInput textarea:hover {
  border: 1px solid #333333;
}

.focus.containerInput textarea {
  border: 1px solid #2962ff;
}
.error textarea {
  border: 1px solid #d32f2f;
}
.focus.error textarea {
  border: 1px solid #d32f2f;
}
